<template>
    <div>
        <b-row>
            <b-col>
                <b-container>
                    <b-row no-gutters class="mb-2">
                        <b-col><b>{{ $t('admin_dashboard_models_outputs') }}</b></b-col>
                    </b-row>
                    <b-row no-gutters class="font-weight-bold"><b-col>Name</b-col><b-col>Pulse</b-col><b-col>Type</b-col><b-col></b-col></b-row>
                    <draggable class="draggable-list" :list="gateIO.outputSignals">
                        <b-row no-gutters class="list-item row" :class="{striped: isOdd(index)}" v-for="(element, index) in gateIO.outputSignals" @click="editOutputSignal(element)">
                            <b-col class="p-2"><b-icon icon="three-dots-vertical"></b-icon> {{ element.name }}</b-col>
                            <b-col class="p-2">{{ element.pulse }}</b-col>
                            <b-col class="p-2">{{ GetOutputTypeText(element.type) }}</b-col>
                            <b-col class="p-2 text-right trash"><b-icon icon="trash" @click.stop="gateIO.outputSignals.splice(index, 1)"></b-icon></b-col>
                        </b-row>
                    </draggable>
                    <b-row class="mt-2">
                        <b-col v-if="gateIO.outputSignals.length < 4" cols="2"><b-button href="#" @click="editOutputSignal()">Add</b-button></b-col>
                    </b-row>
                </b-container>
            </b-col>
            <b-col>
                <b-container>
                    <b-row no-gutters class="mb-2">
                        <b-col><b>{{ $t('admin_dashboard_models_inputs') }}</b></b-col>
                    </b-row>
                    <b-row no-gutters class="font-weight-bold"><b-col>Name</b-col><b-col>Filtertime</b-col><b-col>Type</b-col><b-col></b-col></b-row>
                    <draggable class="draggable-list" :list="gateIO.inputSignals">
                        <b-row no-gutters class="list-item row" :class="{striped: isOdd(index)}" v-for="(element, index) in gateIO.inputSignals" @click="editInputSignal(element)">
                            <b-col class="p-2"><b-icon icon="three-dots-vertical"></b-icon> {{ element.name }}</b-col>
                            <b-col class="p-2">{{ element.filterTime }}</b-col>
                            <b-col class="p-2">{{ GetInputTypeText(element.type) }}</b-col>
                            <b-col class="p-2 text-right trash"><b-icon icon="trash" @click.stop="gateIO.inputSignals.splice(index, 1)"></b-icon></b-col>
                        </b-row>
                    </draggable>
                    <b-row class="mt-2">
                        <b-col v-if="gateIO.inputSignals.length < 16" cols="2"><b-button href="#" @click="editInputSignal()">Add</b-button></b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>


        <b-modal size="lg" id="outputSignal-edit-modal" ref="outputSignal-edit-modal" hide-footer>
            <template #modal-title>
                <h5>
                    Edit output signal
                </h5>
            </template>
            <b-row class="mb-2">
                <b-col>
                    Name
                    <b-form-input v-model="outputSignalInEdit.name"></b-form-input>
                </b-col>
                <b-col>
                    Pulse length
                    <b-select v-model="outputSignalInEdit.pulse" :options="outputPulseLengths"></b-select>
                </b-col>
                <b-col>
                    Select type
                    <b-select v-model="outputSignalInEdit.type" :options="availableOutputSignals"></b-select>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal size="lg" id="inputSignal-edit-modal" ref="inputSignal-edit-modal" hide-footer>
            <template #modal-title>
                <h5>
                    Edit input signal
                </h5>
            </template>
            <b-row class="mb-2">
                <b-col>
                    Name
                    <b-form-input v-model="inputSignalInEdit.name"></b-form-input>
                </b-col>
                <b-col>
                    Filter time
                    <b-form-input v-model="inputSignalInEdit.filterTime"></b-form-input>
                </b-col>
                <b-col>
                    Select type
                    <b-select v-model="inputSignalInEdit.type" :options="availableInputSignals"></b-select>
                </b-col>
            </b-row>
            <b-row v-if="inputSignalInEdit.type == 1000" class="border-top">
                <b-col>
                    <b-row>
                        <b-col>
                            <h5>
                                Custom signal settings
                            </h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div class="mb-2">
                                Label active
                                <b-form-input v-model="inputSignalInEdit.customLabelActive"></b-form-input>
                            </div>
                            <div class="mb-2">
                                Label inactive
                                <b-form-input v-model="inputSignalInEdit.customLabelInactive"></b-form-input>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="6">
                            Select active icon
                            <b-select v-model="inputSignalInEdit.iconActive" :options="icons"></b-select>
                        </b-col>
                        <b-col cols="6">
                            Select inactive icon
                            <b-select v-model="inputSignalInEdit.iconInactive" :options="icons"></b-select>
                        </b-col>
                    </b-row>
                </b-col>
               
            </b-row>
        </b-modal>
    </div>

</template>

<style lang="css" scoped>
    .list-item {
        cursor: grab;
    }

    .trash {
        cursor: pointer;
    }

    .card {
        margin-top: 60px;
        min-width: 600px;
    }

    .card-body {
        background: #f1f0e6;
    }

    .striped {
        background: #dbd9c166;
    }
</style>

<script>
    import { mapState, mapActions } from 'vuex';
    import draggable from "vuedraggable";

    export default {
        props: {
            gateIO: {
                type: [Object],
                // Object or array defaults must be returned from
                // a factory function. The function receives the raw
                // props received by the component as the argument.
                default(rawProps) {
                    return {
                        outputSignals: new Array,
                        inputSignals: new Array
                    }
                }
            },
            availableOutputSignals: new Array,
            availableInputSignals: new Array,
            icons: new Array
        },
        components: {
            draggable,
        },
        data: () => ({

            outputSignalInEdit: {},
            inputSignalInEdit: {},
            outputPulseLengths: [1, 10, 20, 30]
        }),
        methods: {
			editOutputSignal(signal) {

                if (!signal) {
                    if (this.gateIO.outputSignals.length >= 4) {
                        return;
                    }

                    this.outputSignalInEdit = {
                        name: '',
                        pulse: 1,
                        filterTime: 0,
                        type: 0
                    };
                    this.gateIO.outputSignals.push(this.outputSignalInEdit);

                } else {
                    this.outputSignalInEdit = signal;
                }

                this.$refs['outputSignal-edit-modal'].show();
            },
            editInputSignal(signal) {
                if (!signal) {
                    if (this.gateIO.inputSignals.length >= 16) {
                        return;
                    }

                    this.inputSignalInEdit = {
                        name: '',
                        filterTime: 0,
                        type: 0,
                        customLabelActive: '',
                        customLabelInactive: '',
                        iconActive: 0,
                        iconInactive: 0
                    };
                    this.gateIO.inputSignals.push(this.inputSignalInEdit);

                } else {
                    this.inputSignalInEdit = signal;
                }

                this.$refs['inputSignal-edit-modal'].show();
            },
            isOdd(index) {
                if (index % 2 === 0) {
                    return true
                }
            },
            GetOutputTypeText(value) {
                let option = this.availableOutputSignals.find(x => x.value == value);
                if (option != null) {
                    return option.text;
                }
                return '';
            },
            GetInputTypeText(value) {
                let option = this.availableInputSignals.find(x => x.value == value);
                if (option != null) {
                    return option.text;
                }
                return '';
            }
        }
    }
</script>